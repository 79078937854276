<template>
  <div class="page">
    <div class="main">
      <div class="title">用户消息</div>
    </div>

    <el-row style="margin-top: 10px; min-height: 580px">
      <div class="user_list">
        <div class="card" v-for="(item, i) in tableData" :key="i">
          <el-row type="flex" align="center">
            <el-col :span="4">
              <div class="img" @click="toExpertInfo(item.user_id, item.score)">
                <el-avatar :size="50" :src="item.head_img" v-if="item.head_img">
                </el-avatar>
                <el-avatar
                  v-else
                  :size="50"
                  src=""
                >
                </el-avatar>
              </div>
            </el-col>
            <el-col :span="19" :offset="1">
              <div class="name" @click="toExpertInfo(item.user_id, item.score)">
                <span>{{ item.user_name }}</span>
                <span>{{ item.is_wenli == 1 ? " 文科" : " 理科" }}</span>
                <span>{{ item.is_sex == 1 ? " 男" : " 女" }}</span>
                <br />
                <span>{{ item.school }}</span> <span>{{ item.score }}分</span>
              </div>
              <div class="btn">
                <router-link :to="'/news?user_id=' + item.user_id"
                  ><span>消息</span></router-link
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-row>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="eidtMessage">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      dialogVisible: false,
      user_id: "",
      tableData: [],
      formData: {
        id: "",
        titel: "",
        content: "",
        status: 1,
      },
    };
  },
  
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.getList();
  },
  
  methods: {
    getList() {
      const data = {
        expert_id: this.ExpertInfo.id,
      };
      this.$fecth.post("user_message/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tableData = data;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleAdd() {
      this.dialog.title = "新增";
      this.dialog.centerDialogVisible = true;
    },
    handleEdit(id) {
      this.dialog.title = "编辑";
      this.formData.id = id;
      const data = {
        id: id,
      };

      this.$fecth.post("user_message/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },

    handleDel(id) {
      // console.log(id);
      const data = {
        id: id,
      };
      this.$fecth.post("user_message/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, "删除成功");
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    resetForm() {
      this.formData = {
        id: "",
        name: "",
        img: "",
        brief: "",
        original_price: "",
        current_price: "",
        desc: "",
        status: 1,
      };
      this.dialog.centerDialogVisible = false;
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    toExpertInfo(id, score) {
      this.$router.push("/user_info?id=" + id + "&score=" + score);
    },
    messageList(id) {
      this.user_id = id;
      this.dialogVisible = true;
    },
    eidtMessage() {},
  },
};
</script>


<style lang="less" scoped>
.user_list {
  .card {
    display: inline-block;
    margin-right: 10px;
    background: white;
    margin-bottom: 12px;
    padding: 10px;
    width: 32.7%;
    .name {
      line-height: 150%;
    }

    .btn {
      margin-top: 10px;

      span {
        border-radius: 30px;
        border: 1px solid #e3e3e3;
        padding: 1px 7px;
        margin-right: 7px;
        /*font-size: 14px;*/
      }
    }
  }
}
</style>